<template>
  <div>
    <DataTable :options="options" />
    <AddVoucher v-if="dialog" v-model="dialog" :account="account" />
  </div>
</template>

<script>
import { UserService } from '@tutti/services';
import AddVoucher from '@admin/dialogs/add-voucher';

export default {
  components: {
    AddVoucher,
  },

  data() {
    return {
      dialog: false,
      account: null,
      options: {
        export: true,
        action: {
          child: 'account',
          showChildList: true,
          // actions: [
          //   {
          //     tooltip: 'Add voucher/credits',
          //     icon: 'mdi-plus',
          //     click: this.addVoucher,
          //   },
          // ],
        },
        actions: [
          {
            text: 'Reset password',
            body: 'reset-password',
            message: 'reset password for',
          },
          {
            text: 'Resend verification email',
            body: 'verification',
            message: 'resend verification email to',
          },
        ],
        headers: [
          { text: 'Username', value: 'username', filter: true, component: { name: 'dtView', props: { name: 'user' } } },
          { text: 'First name', value: 'firstName', filter: true, capitalize: true },
          { text: 'Last name', value: 'lastName', filter: true, capitalize: true },
          { text: 'Email', value: 'email', filter: true, width: '400px' },
          { text: 'Signup', value: 'strategy', component: { name: 'dtStrategy' }, width: '100px', align: 'center' },
          {
            text: 'Enabled',
            value: 'isActive',
            width: '100px',
            sortable: false,
            align: 'center',
            component: { name: 'dtCheckbox', props: { onChange: this.onStatusChange } },
          },
          {
            text: 'Verified',
            value: 'isVerified',
            width: '100px',
            align: 'center',
            component: { name: 'dtCheckbox', props: { disabled: true } },
          },
        ],
      },
    };
  },
  methods: {
    addVoucher(account) {
      this.account = account;
      this.dialog = true;
    },
    async onStatusChange(value) {
      const confirm = await this.$confirm(value.isActive ? 'ENABLE_USER' : 'DISABLE_USER');

      if (confirm) {
        await UserService.changeStatus({
          userId: value._id,
          isActive: value.isActive,
        }); // TODO
      } else {
        value.isActive = !value.isActive;
      }
    },
  },
};
</script>
